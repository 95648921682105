import { observable, action, makeObservable } from 'mobx';
import { ignore } from 'mobx-sync';

import { FlashMessage } from '@clubspot/frontend-common';

export class ContextStore {
  public flashMessages: FlashMessage[] = [];

  private routeLeavingGuard = false;

  public isRouteLeavingDialogOpen = false;

  private onRouteLeave?: () => void;

  constructor() {
    makeObservable<ContextStore, 'routeLeavingGuard' | 'onRouteLeave'>(this, {
      flashMessages: observable,
      routeLeavingGuard: observable,
      isRouteLeavingDialogOpen: observable,
      onRouteLeave: observable,
      showMessage: action.bound,
      removeMessage: action.bound,
      setRouteLeavingGuard: action.bound,
      openRouteLeavingDialog: action.bound,
      closeRouteLeavingDialog: action.bound,
      confirmRouteLeave: action.bound,
    });
    ignore(this, 'flashMessages');
    ignore(this, 'routeLeavingGuard');
    ignore(this, 'isRouteLeavingDialogOpen');
    ignore(this, 'onRouteLeave');
  }

  public showMessage(message: FlashMessage): void {
    this.flashMessages.push(message);
  }

  public removeMessage(id: string): void {
    const index = this.flashMessages.findIndex((element) => element.id === id);
    this.flashMessages.splice(index);
  }

  public setRouteLeavingGuard(isActive: boolean): void {
    this.routeLeavingGuard = isActive;
  }

  public openRouteLeavingDialog(navigationFunction: () => void): void {
    if (this.routeLeavingGuard) {
      this.isRouteLeavingDialogOpen = true;
      this.onRouteLeave = navigationFunction;
    } else {
      navigationFunction();
    }
  }

  public closeRouteLeavingDialog(): void {
    this.isRouteLeavingDialogOpen = false;
    this.onRouteLeave = undefined;
  }

  public confirmRouteLeave(): void {
    this.routeLeavingGuard = false;
    if (this.onRouteLeave) {
      this.onRouteLeave();
    }
    this.closeRouteLeavingDialog();
  }
}

export default ContextStore;
