import { observable, action, makeObservable } from 'mobx';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { getMemberDataFromFile } from 'models/graphql';

type MemberDataFromFileFormData = getMemberDataFromFile['getMemberDataFromFile']['formData'][number];
interface FileInfo {
  name?: string;
  size?: number;
  type?: string;
}

export enum MembersImportErrorPriorityEnum {
  excelDuplication = '0',
  error = '1',
  dbDuplication = '2',
  noError = '3',
}

export class MembersImportStore {
  public membersImportFileInfo: FileInfo = {};
  public formData: MemberDataFromFileFormData[] = [];
  public schema: RJSFSchema = {};
  public uiSchema: UiSchema = {};
  public fileUploadError = '';
  public selectedMembershipTypeId?: number;

  constructor() {
    makeObservable(this, {
      membersImportFileInfo: observable,
      formData: observable,
      schema: observable,
      uiSchema: observable,
      fileUploadError: observable,
      selectedMembershipTypeId: observable,
      setMembersImportFileInfo: action.bound,
      resetMembersImportFileInfo: action.bound,
      resetMembersImportStore: action.bound,
      setFileUploadError: action.bound,
      setSelectedMembershipTypeId: action.bound,
      setMemberDataFromFileResponse: action.bound,
      setFormData: action.bound,
    });
  }

  public setMembersImportFileInfo = (membersImportFileInfo: FileInfo) => {
    this.membersImportFileInfo = membersImportFileInfo;
  };

  public resetMembersImportFileInfo = () => {
    this.membersImportFileInfo = {};
    this.fileUploadError = '';
  };

  public resetMembersImportStore = () => {
    this.resetMembersImportFileInfo();
    this.setSelectedMembershipTypeId(undefined);
    this.schema = {};
    this.uiSchema = {};
    this.formData = [];
  };

  public setFileUploadError = (fileUploadError:string) => {
    this.fileUploadError = fileUploadError;
  };

  public setSelectedMembershipTypeId = (selectedMembershipTypeId?: number) => {
    this.selectedMembershipTypeId = selectedMembershipTypeId;
  };

  public setMemberDataFromFileResponse = (
    formData: MemberDataFromFileFormData[],
    schema: RJSFSchema,
    uiSchema: UiSchema,
    fileUploadError:string,
  ) => {
    if (fileUploadError === '') {
      this.formData = formData;
      this.schema = schema;
      this.uiSchema = uiSchema;
    } else {
      this.formData = [];
      this.schema = {};
      this.uiSchema = {};
    }
    this.fileUploadError = fileUploadError;
  };

  public setFormData = (
    formData: MemberDataFromFileFormData[],
  ) => {
    this.formData = formData;
  };
}
