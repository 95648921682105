import remotedev from 'mobx-remotedev';

import { hydrateStores as baseHydrateStores,
  createStores as baseCreateStores,
  useStores as baseUseStores,
} from '@clubspot/frontend-common';
import { ContextStore } from './ContextStore';
import { AuthStore } from './AuthStore';
import { FundraiserStore } from './FundraiserStore';
import { MembersImportStore } from './MembersImportStore';

const fundraiserStore = new FundraiserStore();
const contextStore = new ContextStore();
const authStore = new AuthStore();
const membersImportStore = new MembersImportStore();

const stores = {
  contextStore: remotedev(contextStore, { name: 'context' }),
  authStore: remotedev(authStore, { name: 'auth' }),
  fundraiserStore: remotedev(fundraiserStore, { name: 'fundraiser' }),
  membersImportStore: remotedev(membersImportStore, { name: 'membersImport' }),
};

export const hydrateStores = async () => {
  await baseHydrateStores(stores);
  if (authStore.isLoggedIn) {
    await authStore.checkSession();
  }
};

export function createStores() {
  return baseCreateStores(stores);
}

export type Stores = typeof stores;

export function useStores() {
  return baseUseStores<typeof stores>();
}
