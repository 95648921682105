import { observable, action, makeObservable } from 'mobx';
import { ignore } from 'mobx-sync';


export class FundraiserStore {
  public winningNumbers?: number[];

  public roundId?: number;

  public randomGeneration?: boolean;

  public matchThreeNumbers?: boolean;

  public numberOfBalls?: number;

  constructor() {
    makeObservable(this, {
      winningNumbers: observable,
      roundId: observable,
      randomGeneration: observable,
      matchThreeNumbers: observable,
      numberOfBalls: observable,
      setRandomGeneration: action.bound,
      setMatchThreeNumbers: action.bound,
      setNumberOfBalls: action.bound,
      addNumbers: action.bound,
      addRoundId: action.bound,
    });
    ignore(this, 'winningNumbers');
    ignore(this, 'roundId');
    ignore(this, 'randomGeneration');
    ignore(this, 'matchThreeNumbers');
    ignore(this, 'numberOfBalls');
  }

  public setRandomGeneration = (randomGeneration?: boolean) => {
    this.randomGeneration = randomGeneration;
  };

  public setMatchThreeNumbers = (matchThreeNumbers?: boolean) => {
    this.matchThreeNumbers = matchThreeNumbers;
  };

  public setNumberOfBalls = (numberOfBalls?: number) => {
    this.numberOfBalls = numberOfBalls;
  };

  public addNumbers = (numbers: number[]) => {
    this.winningNumbers = numbers;
  };

  public addRoundId = (id: number) => {
    this.roundId = id;
  };
}
