import { action, computed, observable, makeObservable } from 'mobx';

import { get, post, MAINTENANCE_PATH, checkBackendHealth } from '@clubspot/frontend-common';
import { ClubStatus, Clubtype, Currency, UserType } from 'models/graphql';
import { Timezone } from 'graphql-timezone';

export type ClubFeatures = {
  fundraisers: boolean;
  fundraisersOnMemberSite: boolean;
  openFundraisers: boolean;
  closedFundraisers: boolean;
  tickets: boolean;
  membership: boolean;
  hasAnyMemberships: boolean;
};
export interface LoginResponse {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  type: UserType;
  clubId: number;
  clubName: string;
  clubLogo: string;
  clubFeatures: ClubFeatures;
  clubStatus: string;
  clubType: Clubtype;
  currency: Currency;
  timezone: Timezone;
}

export class AuthStore {
  public userId?: number;
  public email?: string;
  public firstName?: string;
  public lastName?: string;
  public phoneNumber?: string | null;
  public type?: UserType;
  private clubId?: number;
  public clubName?: string;
  public clubLogo?: string;
  public clubFeatures?: ClubFeatures;
  public clubStatus?: ClubStatus;
  public clubType?: Clubtype;
  public currency: Currency = Currency.EUR;
  public timezone: Timezone = Timezone.EuropeDublin;

  public constructor() {
    makeObservable<AuthStore, 'clubId'>(this, {
      userId: observable,
      email: observable,
      firstName: observable,
      lastName: observable,
      phoneNumber: observable,
      type: observable,
      clubId: observable,
      clubName: observable,
      clubLogo: observable,
      clubFeatures: observable,
      clubStatus: observable,
      clubType: observable,
      currency: observable,
      timezone: observable,
      isLoggedIn: computed,
      ClubId: computed,
      isGAATypeClub: computed,
      login: action.bound,
      logout: action.bound,
    });

    if (this.isLoggedIn) {
      void this.checkSession();
    }
  }

  async checkSession() {
    try {
      const response = await get<LoginResponse>('auth/whoami');
      this.login(response);
    } catch (err) {
      const isBackendHealthy = await checkBackendHealth();
      if (((err as Error).message === 'Service Unavailable' || !isBackendHealthy) && window.location.pathname !== MAINTENANCE_PATH) {
        window.location.href = MAINTENANCE_PATH;
      } else if (window.location.pathname !== MAINTENANCE_PATH) {
        this.logout();
      }
    }
  }

  get isLoggedIn() {
    return this.userId != null;
  }

  get ClubId(): number {
    if (!this.clubId) {
      throw new Error('Club ID missing!');
    }
    return this.clubId;
  }

  get isGAATypeClub(): boolean {
    return [
      `${Clubtype.gaaLgfaCamogie}`,
      `${Clubtype.louthGAA}`,
    ].includes(`${this.clubType}`);
  }

  public login(
    {
      id,
      email,
      firstName,
      lastName,
      type,
      clubId,
      clubName,
      clubLogo,
      phoneNumber,
      clubFeatures,
      clubStatus,
      clubType,
      currency,
      timezone,
    }: LoginResponse,
  ): void {
    this.userId = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.type = type;
    this.clubId = clubId;
    this.clubName = clubName;
    this.clubLogo = clubLogo;
    this.clubFeatures = clubFeatures;
    this.clubType = clubType;
    this.currency = currency;
    this.timezone = timezone;

    switch (clubStatus) {
    case 'signupCompleted':
      this.clubStatus = ClubStatus.completed;
      break;
    case 'loggedIn':
      this.clubStatus = ClubStatus.loggedIn;
      break;
    case 'invitationSent':
      this.clubStatus = ClubStatus.invited;
      break;
    default:
      this.clubStatus = undefined;
    }
  }

  public logout(): void {
    this.email = undefined;
    this.userId = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.phoneNumber = undefined;
    this.type = undefined;
    this.clubId = undefined;
    this.clubName = undefined;
    this.clubLogo = undefined;
    this.clubFeatures = undefined;
    this.clubStatus = undefined;
    this.clubType = undefined;
    void post<{}>('auth/logout');
  }
}

export default AuthStore;
