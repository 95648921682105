import { lazy, Suspense, FunctionComponentElement } from 'react';
import ReactDOM from 'react-dom';
import { CircularProgress } from '@mui/material';

import { hydrateStores } from 'stores';

const App = lazy(() => import('./App'));


const withSuspense = (component: FunctionComponentElement<{}>) => (
  <Suspense fallback={<CircularProgress />}>
    {component}
  </Suspense>
);

async function init() {
  await hydrateStores();
  ReactDOM.render(withSuspense(<App />), document.getElementById('app'));
}

void init();
